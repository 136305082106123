var andromeeda = Object.freeze({
  "colors": {
    "activityBar.background": "#23262E",
    "activityBar.dropBackground": "#3a404e",
    "activityBar.foreground": "#BAAFC0",
    "activityBarBadge.background": "#00b0ff",
    "activityBarBadge.foreground": "#20232B",
    "badge.background": "#00b0ff",
    "badge.foreground": "#20232B",
    "button.background": "#00e8c5cc",
    "button.hoverBackground": "#07d4b6cc",
    "debugExceptionWidget.background": "#FF9F2E60",
    "debugExceptionWidget.border": "#FF9F2E60",
    "debugToolBar.background": "#20232A",
    "diffEditor.insertedTextBackground": "#29BF1220",
    "diffEditor.removedTextBackground": "#F21B3F20",
    "dropdown.background": "#2b303b",
    "dropdown.border": "#363c49",
    "editor.background": "#23262E",
    "editor.findMatchBackground": "#f39d1256",
    "editor.findMatchBorder": "#f39d12b6",
    "editor.findMatchHighlightBackground": "#59b8b377",
    "editor.foreground": "#D5CED9",
    "editor.hoverHighlightBackground": "#373941",
    "editor.lineHighlightBackground": "#2e323d",
    "editor.lineHighlightBorder": "#2e323d",
    "editor.rangeHighlightBackground": "#372F3C",
    "editor.selectionBackground": "#3D4352",
    "editor.selectionHighlightBackground": "#4F435580",
    "editor.wordHighlightBackground": "#4F4355",
    "editor.wordHighlightStrongBackground": "#db45a280",
    "editorBracketMatch.background": "#746f77",
    "editorBracketMatch.border": "#746f77",
    "editorCodeLens.foreground": "#746f77",
    "editorCursor.foreground": "#FFF",
    "editorError.foreground": "#FC644D",
    "editorGroup.background": "#23262E",
    "editorGroup.dropBackground": "#495061d7",
    "editorGroupHeader.tabsBackground": "#23262E",
    "editorGutter.addedBackground": "#9BC53DBB",
    "editorGutter.deletedBackground": "#FC644DBB",
    "editorGutter.modifiedBackground": "#5BC0EBBB",
    "editorHoverWidget.background": "#373941",
    "editorHoverWidget.border": "#00e8c5cc",
    "editorIndentGuide.activeBackground": "#585C66",
    "editorIndentGuide.background": "#333844",
    "editorLineNumber.foreground": "#746f77",
    "editorLink.activeForeground": "#3B79C7",
    "editorOverviewRuler.border": "#1B1D23",
    "editorRuler.foreground": "#4F4355",
    "editorSuggestWidget.background": "#20232A",
    "editorSuggestWidget.border": "#372F3C",
    "editorSuggestWidget.selectedBackground": "#373941",
    "editorWarning.foreground": "#FF9F2E",
    "editorWhitespace.foreground": "#333844",
    "editorWidget.background": "#20232A",
    "errorForeground": "#FC644D",
    "extensionButton.prominentBackground": "#07d4b6cc",
    "extensionButton.prominentHoverBackground": "#07d4b5b0",
    "focusBorder": "#746f77",
    "foreground": "#D5CED9",
    "gitDecoration.ignoredResourceForeground": "#555555",
    "input.background": "#2b303b",
    "input.placeholderForeground": "#746f77",
    "inputOption.activeBorder": "#C668BA",
    "inputValidation.errorBackground": "#D65343",
    "inputValidation.errorBorder": "#D65343",
    "inputValidation.infoBackground": "#3A6395",
    "inputValidation.infoBorder": "#3A6395",
    "inputValidation.warningBackground": "#DE9237",
    "inputValidation.warningBorder": "#DE9237",
    "list.activeSelectionBackground": "#23262E",
    "list.activeSelectionForeground": "#00e8c6",
    "list.dropBackground": "#3a404e",
    "list.focusBackground": "#282b35",
    "list.focusForeground": "#eee",
    "list.hoverBackground": "#23262E",
    "list.hoverForeground": "#eee",
    "list.inactiveSelectionBackground": "#23262E",
    "list.inactiveSelectionForeground": "#00e8c6",
    "merge.currentContentBackground": "#F9267240",
    "merge.currentHeaderBackground": "#F92672",
    "merge.incomingContentBackground": "#3B79C740",
    "merge.incomingHeaderBackground": "#3B79C7BB",
    "minimapSlider.activeBackground": "#60698060",
    "minimapSlider.background": "#58607460",
    "minimapSlider.hoverBackground": "#60698060",
    "notification.background": "#2d313b",
    "notification.buttonBackground": "#00e8c5cc",
    "notification.buttonHoverBackground": "#07d4b5b0",
    "notification.errorBackground": "#FC644D",
    "notification.infoBackground": "#00b0ff",
    "notification.warningBackground": "#FF9F2E",
    "panel.background": "#23262E",
    "panel.border": "#1B1D23",
    "panelTitle.activeBorder": "#23262E",
    "panelTitle.inactiveForeground": "#746f77",
    "peekView.border": "#23262E",
    "peekViewEditor.background": "#1A1C22",
    "peekViewEditor.matchHighlightBackground": "#FF9F2E60",
    "peekViewResult.background": "#1A1C22",
    "peekViewResult.matchHighlightBackground": "#FF9F2E60",
    "peekViewResult.selectionBackground": "#23262E",
    "peekViewTitle.background": "#1A1C22",
    "peekViewTitleDescription.foreground": "#746f77",
    "pickerGroup.border": "#4F4355",
    "pickerGroup.foreground": "#746f77",
    "progressBar.background": "#C668BA",
    "scrollbar.shadow": "#23262E",
    "scrollbarSlider.activeBackground": "#3A3F4CCC",
    "scrollbarSlider.background": "#3A3F4C77",
    "scrollbarSlider.hoverBackground": "#3A3F4CAA",
    "selection.background": "#746f77",
    "sideBar.background": "#23262E",
    "sideBar.foreground": "#999999",
    "sideBarSectionHeader.background": "#23262E",
    "sideBarTitle.foreground": "#00e8c6",
    "statusBar.background": "#23262E",
    "statusBar.debuggingBackground": "#FC644D",
    "statusBar.noFolderBackground": "#23262E",
    "statusBarItem.activeBackground": "#00e8c5cc",
    "statusBarItem.hoverBackground": "#07d4b5b0",
    "statusBarItem.prominentBackground": "#07d4b5b0",
    "statusBarItem.prominentHoverBackground": "#00e8c5cc",
    "tab.activeBackground": "#23262e",
    "tab.activeBorder": "#00e8c6",
    "tab.activeForeground": "#00e8c6",
    "tab.inactiveBackground": "#23262E",
    "tab.inactiveForeground": "#746f77",
    "terminal.ansiBlue": "#7cb7ff",
    "terminal.ansiBrightBlue": "#7cb7ff",
    "terminal.ansiBrightCyan": "#00e8c6",
    "terminal.ansiBrightGreen": "#96E072",
    "terminal.ansiBrightMagenta": "#ff00aa",
    "terminal.ansiBrightRed": "#ee5d43",
    "terminal.ansiBrightYellow": "#FFE66D",
    "terminal.ansiCyan": "#00e8c6",
    "terminal.ansiGreen": "#96E072",
    "terminal.ansiMagenta": "#ff00aa",
    "terminal.ansiRed": "#ee5d43",
    "terminal.ansiYellow": "#FFE66D",
    "terminalCursor.background": "#23262E",
    "terminalCursor.foreground": "#FFE66D",
    "titleBar.activeBackground": "#23262E",
    "walkThrough.embeddedEditorBackground": "#23262E",
    "widget.shadow": "#14151A"
  },
  "displayName": "Andromeeda",
  "name": "andromeeda",
  "tokenColors": [
    {
      "settings": {
        "background": "#23262E",
        "foreground": "#D5CED9"
      }
    },
    {
      "scope": [
        "comment",
        "markup.quote.markdown",
        "meta.diff",
        "meta.diff.header"
      ],
      "settings": {
        "foreground": "#A0A1A7cc"
      }
    },
    {
      "scope": [
        "meta.template.expression.js",
        "constant.name.attribute.tag.jade",
        "punctuation.definition.metadata.markdown",
        "punctuation.definition.string.end.markdown",
        "punctuation.definition.string.begin.markdown"
      ],
      "settings": {
        "foreground": "#D5CED9"
      }
    },
    {
      "scope": [
        "variable",
        "support.variable",
        "entity.name.tag.yaml",
        "constant.character.entity.html",
        "source.css entity.name.tag.reference",
        "beginning.punctuation.definition.list.markdown",
        "source.css entity.other.attribute-name.parent-selector",
        "meta.structure.dictionary.json support.type.property-name"
      ],
      "settings": {
        "foreground": "#00e8c6"
      }
    },
    {
      "scope": [
        "markup.bold",
        "constant.numeric",
        "meta.group.regexp",
        "constant.other.php",
        "support.constant.ext.php",
        "constant.other.class.php",
        "support.constant.core.php",
        "fenced_code.block.language",
        "constant.other.caps.python",
        "entity.other.attribute-name",
        "support.type.exception.python",
        "source.css keyword.other.unit",
        "variable.other.object.property.js.jsx",
        "variable.other.object.js"
      ],
      "settings": {
        "foreground": "#f39c12"
      }
    },
    {
      "scope": [
        "markup.list",
        "text.xml string",
        "entity.name.type",
        "support.function",
        "entity.other.attribute-name",
        "meta.at-rule.extend",
        "entity.name.function",
        "entity.other.inherited-class",
        "entity.other.keyframe-offset.css",
        "text.html.markdown string.quoted",
        "meta.function-call.generic.python",
        "meta.at-rule.extend support.constant",
        "entity.other.attribute-name.class.jade",
        "source.css entity.other.attribute-name",
        "text.xml punctuation.definition.string"
      ],
      "settings": {
        "foreground": "#FFE66D"
      }
    },
    {
      "scope": [
        "markup.heading",
        "variable.language.this.js",
        "variable.language.special.self.python"
      ],
      "settings": {
        "foreground": "#ff00aa"
      }
    },
    {
      "scope": [
        "punctuation.definition.interpolation",
        "punctuation.section.embedded.end.php",
        "punctuation.section.embedded.end.ruby",
        "punctuation.section.embedded.begin.php",
        "punctuation.section.embedded.begin.ruby",
        "punctuation.definition.template-expression",
        "entity.name.tag"
      ],
      "settings": {
        "foreground": "#f92672"
      }
    },
    {
      "scope": [
        "storage",
        "keyword",
        "meta.link",
        "meta.image",
        "markup.italic",
        "source.js support.type"
      ],
      "settings": {
        "foreground": "#c74ded"
      }
    },
    {
      "scope": [
        "string.regexp",
        "markup.changed"
      ],
      "settings": {
        "foreground": "#7cb7ff"
      }
    },
    {
      "scope": [
        "constant",
        "support.class",
        "keyword.operator",
        "support.constant",
        "text.html.markdown string",
        "source.css support.function",
        "source.php support.function",
        "support.function.magic.python",
        "entity.other.attribute-name.id",
        "markup.deleted"
      ],
      "settings": {
        "foreground": "#ee5d43"
      }
    },
    {
      "scope": [
        "string",
        "text.html.php string",
        "markup.inline.raw",
        "markup.inserted",
        "punctuation.definition.string",
        "punctuation.definition.markdown",
        "text.html meta.embedded source.js string",
        "text.html.php punctuation.definition.string",
        "text.html meta.embedded source.js punctuation.definition.string",
        "text.html punctuation.definition.string",
        "text.html string"
      ],
      "settings": {
        "foreground": "#96E072"
      }
    },
    {
      "scope": [
        "entity.other.inherited-class"
      ],
      "settings": {
        "fontStyle": "underline"
      }
    }
  ],
  "type": "dark"
});

export { andromeeda as default };
